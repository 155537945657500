.letterbox-detail {
	padding: 10px;
	background-color: white;
	margin-top: 10px;
}

.letterbox-detail > h2 {
	font-size: 23px;
}

.letterbox {
	padding-top: 10px;
	margin-top: 10px;
	border-top: 1px solid #ddd;
}

.letterbox:not(:last-child) {
	margin-bottom: 10px;
}

.letterbox .content {
	margin-top: 10px;
}

.letterbox-font h1 {
	font-size: 22px;
}

.letterbox-font h2 {
	font-size: 20px;
}

.letterbox-font h3 h4 h5 {
	font-size: 18px;
}

.letterbox-font h3 {
	font-size: 16px;
}

.letterbox-font p {
	margin-bottom: 5px;
}

